@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

/* Custom styles for pagination bullets */
.swiper-pagination-bullet {
    @apply w-3 h-3 bg-black opacity-70 mx-1 rounded-full transition-transform duration-300 ease-in-out;
  }
  
  .swiper-pagination-bullet-active {
    @apply bg-yellow-700 scale-125 opacity-100 shadow-md;
  }
  


/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.left-to-right {
    animation: left-right linear;
    animation-timeline: view();
    animation-range: entry 0;
}

.right-to-left {
    animation: right-left linear;
    animation-timeline: view();
    animation-range: entry 0;
}

@keyframes right-left {
    0% {
        /* transform: translateX(200%); */
        scale: 0;
    }
    100% {
        /* transform: translateX(0); */
        scale: 1;
    }
}

@keyframes left-right {
    0% {
        /* scale: 0; */
        transform: translateX(-200%);
    }
    100% {
        /* scale: 1; */
        transform: translateX(0%);
    }   
}